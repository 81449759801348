<template>
    <div>
        <EditProfileForm 
        />
    </div>
</template>
<script>
import EditProfileForm from './PerfilUsuario/EditProfileForm.vue';
export default {
    components: {
        EditProfileForm,
    },
};
</script>
<style>
</style>
